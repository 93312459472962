<script setup>
    import { ref, inject, computed } from 'vue';
    import { InjectionKeys } from 'o365-utils';
    import OffCanvas from 'o365.vue.components.OffCanvas.vue';
    import ODeveloperMenu from "o365.vue.components.ODeveloperMenu.vue";
    import AppButton from 'o365.pwa.vue.components.layout.AppButton.vue';

    const { pwaStoreKey } = InjectionKeys;

    const { state: offlineState, setDebugUi, setDebugMergeProc, clearOfflineDataProc, setSkipCheckIn } = inject(pwaStoreKey);
    
    const offCanvasRef = ref(null);
    const runningOfflineDataProc = ref(false);

    const toggleOffCanvas = () => {
        if (offCanvasRef.value) {
            offCanvasRef.value.show();
        }
    }

    const debugPwaUi = computed({
        get() {
            return offlineState?.pwaState?.debugUi ?? false;
        },
        set(value) {
            if (!offlineState?.pwaState) {
                return;
            }

            setDebugUi(value);
        }
    });

    const debugMergeProc = computed({
        get() {
            return offlineState?.pwaState?.debugMergeProc ?? false;
        },
        set(value) {
            if (!offlineState?.pwaState) {
                return;
            }

            setDebugMergeProc(value);
        }
    });

    const skipCheckIn = computed({
        get() {
            return offlineState?.pwaState?.skipCheckIn ?? false;
        },
        set(value) {
            if (!offlineState?.pwaState) {
                return;
            }

            setSkipCheckIn(value);
        }
    });

    const runCleanProc = async () => {
        runningOfflineDataProc.value = true;

        try {
            await clearOfflineDataProc();
        } catch (e){
            console.error(e);
        }

        runningOfflineDataProc.value = false;
        
        return;
    }
</script>

<template>
    <button class="btn btn-link text-white fs-5" @click="toggleOffCanvas">
        <i class="bi bi-code-slash align-self-center"></i>
    </button>

    <OffCanvas name="canvas" ref="offCanvasRef" position="right">
        <div class="offcanvas-header border-b border">
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div class="offcanvas-body bg-light">
            <div class="d-flex flex-column bg-light rounded gap-2 p-2">
                <h4 class="m-0">{{ $t('Developer') }}</h4>

                <hr class="m-0" />

                <div class="d-flex flex-column gap-2">
                    <AppButton>
                        <template #buttonContent>
                            <ODeveloperMenu :showTitle="true"></ODeveloperMenu>
                        </template>
                    </AppButton>

                    <AppButton :buttonText="$t('Open apps-setup')" openLink="/nt/apps-setup" iconClass="bi-link"></AppButton>

                    <AppButton @click="runCleanProc()">
                        <template #buttonContent>
                            <div class="d-flex gap-1 align-items-center">
                            <template v-if="!runningOfflineDataProc">
                                <i class="bi bi-trash"></i>
                            </template>
                            <template v-else>
                                <div class="spinner-border spinner-border-sm text-primary" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </template>
                                <span>{{ $t('Clean offline data') }}</span>
                            </div>
                        </template>
                    </AppButton>

                    <AppButton>
                        <template #buttonContent>
                            <div class="form-check form-switch form-check-inline" v-if="offlineState?.pwaState">
                                <input class="form-check-input" v-model="debugPwaUi" type="checkbox"
                                    id="o365-development-menu-debug-pwa-ui-switch">
                                <label class="form-check-label" for="o365-development-menu-debug-pwa-ui-switch">{{ $t('Debug Offline UI')
                                }}</label>
                            </div>
                        </template>
                    </AppButton>

                    <AppButton>
                        <template #buttonContent>
                            <div class="form-check form-switch form-check-inline" v-if="offlineState?.pwaState">
                                <input class="form-check-input" v-model="debugMergeProc" type="checkbox"
                                    id="o365-development-menu-debug-merge-proc-switch">
                                <label class="form-check-label" for="o365-development-menu-debug-merge-proc-switch">{{ $t('Debug Merge')
                                }}</label>
                            </div>
                        </template>
                    </AppButton>
                    <AppButton>
                        <template #buttonContent>
                            <div class="form-check form-switch form-check-inline" v-if="offlineState?.pwaState">
                                <input class="form-check-input" v-model="skipCheckIn" type="checkbox"
                                    id="o365-development-menu-debug-merge-proc-switch">
                                <label class="form-check-label" for="o365-development-menu-skip-checkin-switch">{{ $t('Skip CheckIn') }}</label>
                            </div>
                        </template>
                    </AppButton>
                </div>
            </div>
        </div>
    </OffCanvas>
</template>